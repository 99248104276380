import { authUserAtom } from '@/global-state/jotai-atoms'
import { LocalForageKeys } from '@/global-state/locastorage-key'
import { Alert, AlertTitle, Grid } from '@mui/material'
import { useAtom } from 'jotai'
import localforage from 'localforage'
import React, { useEffect, useState } from 'react'

type Props = {
  totalCount: number
}

const WelcomeSalonsAlert: React.FC<Props> = (props) => {
  const { totalCount } = props
  const [authUser] = useAtom(authUserAtom)
  const [openWelcomeCheck, setOpenWelcomeCheck] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(async () => {
      const result = await localforage.getItem(LocalForageKeys.WelcomeCheck)
      if (!result) {
        setOpenWelcomeCheck(true)
        return
      }
    }, 0)
  }, [authUser?.lineUserId])

  const onClose = () => {
    setOpenWelcomeCheck(false)
    localforage.setItem(LocalForageKeys.WelcomeCheck, true)
  }

  if (totalCount === 0) {
    return (
      <Grid container justifyContent={'center'} px={2}>
        <Alert severity="warning">
          <AlertTitle>ユーザーが見つかりません</AlertTitle>
          お探しのユーザーは見つかりませんでした。
        </Alert>
      </Grid>
    )
  }

  if (openWelcomeCheck) {
    return (
      <Grid container justifyContent={'center'} px={2}>
        <Alert severity="info" onClose={onClose}>
          <AlertTitle>Salonsへようこそ!!</AlertTitle>
          はじめまして、ご登録ありがとうございます！
          <br />
          まずは、お気に入りのユーザーを見つけてみましょう！
        </Alert>
      </Grid>
    )
  }

  return <></>
}
export default React.memo(WelcomeSalonsAlert)
