import { APIPaths } from '@/apis/api-config'
import { notojp } from '@/styles/app-typography'
import { Tab, Tabs, css } from '@mui/material'
import React, { useEffect, useState } from 'react'

type Props = {
  queryParams: APIPaths['/app/users/list']['get']['parameters']['query']
  setQueryParams: (queryParams: APIPaths['/app/users/list']['get']['parameters']['query']) => void
}

const UserFilterTab: React.FC<Props> = (props) => {
  const { queryParams, setQueryParams } = props
  const [value, setValue] = useState<number>(1)

  useEffect(() => {
    if (queryParams?.order_by === 'createdAt') {
      return setValue(1)
    }
    if (queryParams?.query_is_favorite) {
      return setValue(2)
    }
    if (queryParams?.query_is_exists_conversation) {
      return setValue(3)
    }
  }, [queryParams])

  const onChangeTab = (_: React.SyntheticEvent, value: number) => {
    setValue(value)
    if (value === 1) {
      return setQueryParams({
        ...queryParams,
        order_by: 'createdAt',
        order_by_asc_desc: 'desc',
        query_is_exists_conversation: undefined,
        query_is_favorite: undefined,
      })
    }
    if (value === 2) {
      return setQueryParams({ ...queryParams, query_is_exists_conversation: undefined, query_is_favorite: true })
    }
    if (value === 3) {
      return setQueryParams({ ...queryParams, query_is_exists_conversation: true, query_is_favorite: undefined })
    }
  }

  return (
    <Tabs value={value} onChange={onChangeTab} css={tabsStyled} variant="fullWidth">
      <Tab label="新規登録順" value={1} />
      <Tab label="お気に入り" value={2} />
      <Tab label="やりとり中" value={3} />
    </Tabs>
  )
}
export default React.memo(UserFilterTab)

const tabsStyled = css`
    & .MuiTabs-flexContainer {
      max-width: 768px;
      width: 100vw;
    }
    & .Mui-selected {
        font-weight: 500;
    }
    button {
        font-family: ${notojp.style.fontFamily};
        font-size: 14px;
        font-weight: 400;
    }
`
