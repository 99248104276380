import { APISchema } from '@/apis/api-config'
import { INDUSTRY_CATEGORY_MODEL, JOB_TYPE_SELECT_MODEL } from '@/apis/api-enum-jpy'
import { authUserAtom } from '@/global-state/jotai-atoms'
import { appColor } from '@/styles/app-colors'
import { notojp } from '@/styles/app-typography'
import { textTruncate } from '@/utils/text-utils'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import { Avatar, Button, Card, Chip, Grid, css } from '@mui/material'
import { useAtom } from 'jotai'
import { useRouter } from 'next/router'
import React from 'react'
import ProfileCardButton from '../button/ProfileCardButton'

type Props = {
  user: APISchema['User']
}

const ProfileCard: React.FC<Props> = (props) => {
  const router = useRouter()
  const { user } = props
  const [authUser] = useAtom(authUserAtom)

  const profile = user?.profile
  const industryTypeJpy = INDUSTRY_CATEGORY_MODEL.find((v) => v.type === profile?.industryCategoryType)?.value
  const jobTypeJpy = JOB_TYPE_SELECT_MODEL.find((v) => v.type === profile?.jobCategoryType)?.value
  const firstActivityAreaName = profile?.activityArea?.[0]?.areaName || ''
  const orderFirstPostProfile = profile?.userPostProfile?.find((v) => v.order === 0)?.content || ''
  const isFavorite = !!authUser?.UserFavoriteUserRels?.find((v) => v.favoriteUser?.userId === user.id)

  const onClickCard = async () => {
    await router.push(`/home/${user.id}`)
  }

  return (
    <Card elevation={3} css={cardContainerStyled}>
      <Grid container css={paperContainerStyled} px={2} py={1.5}>
        <Grid container onClick={onClickCard}>
          <Grid item py={1}>
            <Avatar variant="rounded" css={avatarStyled} src={profile?.profileImage || ''} />
          </Grid>
          <Grid item pl={1} flexGrow={1}>
            <Grid item css={nameStyled} container>
              <Grid item xs={10}>
                {textTruncate(profile?.name || '')}
              </Grid>
              <Grid item flexGrow={1} textAlign={'end'}>
                {isFavorite ? (
                  <FavoriteIcon color="secondary" css={heartIconStyled(appColor.Service.pink)} />
                ) : (
                  <FavoriteBorderIcon css={heartIconStyled(appColor.Service.grayHard)} />
                )}
              </Grid>
            </Grid>
            <Grid pt={0.5}>
              <Chip label={industryTypeJpy} variant="outlined" css={chipStyled} />
              <Chip label={jobTypeJpy} variant="outlined" css={chipStyled} />
            </Grid>
            <Grid pt={0.5}>
              <Chip icon={<HomeWorkIcon />} label={firstActivityAreaName} variant="outlined" css={areaChipStyled} />
            </Grid>
          </Grid>
          <Grid item xs={12} mt={1} css={backgroundStyled}>
            <Button variant="text" endIcon={<ExpandMoreIcon />} fullWidth css={buttonStyled}>
              <span>{orderFirstPostProfile}</span>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={1}>
          <ProfileCardButton user={user} />
        </Grid>
      </Grid>
    </Card>
  )
}

export default React.memo(ProfileCard)

const cardContainerStyled = css`
  border-radius: 8px;
`

const paperContainerStyled = css`
    background-color: ${appColor.App.white};
    border-radius: 8px;
    font-family: ${notojp.style.fontFamily};
    color: ${appColor.Text.primary};
    cursor: pointer;
`

const heartIconStyled = (color: string) => css`
    width: 20px;
    height: 20px;
    fill: ${color};
`

const avatarStyled = css`
    width: 64px;
    height: 64px;
    border-radius: 16px;
`

const nameStyled = css`
    font-family: ${notojp.style.fontFamily};
    font-size: 14px;
    font-weight: 500;
    color: ${appColor.Text.primary};
`
const chipStyled = css`
    font-family: ${notojp.style.fontFamily};
    font-size: 11px;
    font-weight: 500;
    color: ${appColor.Service.grayHard};
    margin-right: 8px;
    border: 1px solid ${appColor.Service.grayHard};
    background-color: ${appColor.App.white};
    height: 22px;
    max-width: 30vw;
`

const areaChipStyled = css`
    font-family: ${notojp.style.fontFamily};
    font-size: 11px;
    font-weight: 500;
    color: ${appColor.Service.green};
    margin-right: 8px;
    border: 1px solid ${appColor.Service.green};
    background-color: ${appColor.App.white};
    height: 22px;
    svg {
        fill: ${appColor.Service.green};
        width: 16px;
        height: 16px;
    }
`

const backgroundStyled = css`
  background-color: ${appColor.Background.navBar};
  border-radius: 8px;
  & .MuiSvgIcon-root {
    font-family: ${notojp.style.fontFamily};
    font-size: 24px;
    font-weight: 500;
    color: ${appColor.Text.primary};
    padding: 0;
  }
`

const buttonStyled = css`
    pointer-events: none;
    margin: 0;
    padding: 0 16px 0 8px;
    font-family: ${notojp.style.fontFamily};
    font-size: 11px;
    font-weight: 400;
    color: ${appColor.Text.primary};
    height: 22px;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-bottom: 3px;
      max-width: 90%;
    }
`
