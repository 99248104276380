import { APIPaths, APISchema } from '@/apis/api-config'
import { INDUSTRY_CATEGORY_MODEL, JOB_TYPE_SELECT_MODEL } from '@/apis/api-enum-jpy'
import { appColor } from '@/styles/app-colors'
import { appPrimaryButtonStyled } from '@/styles/app-custom-mui'
import { notojp } from '@/styles/app-typography'
import { jpKantouAreas } from '@/utils/jp-area'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import TuneIcon from '@mui/icons-material/Tune'
import {
  Button,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  css,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

type Props = {
  queryParams: APIPaths['/app/users/list']['get']['parameters']['query']
  setQueryParams: (queryParams: APIPaths['/app/users/list']['get']['parameters']['query']) => void
}

const UserFilterDialog: React.FC<Props> = (props) => {
  const { queryParams, setQueryParams } = props
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState<APIPaths['/app/users/list']['get']['parameters']['query']>({})

  useEffect(() => {
    setQuery(queryParams)
  }, [queryParams])

  const onClose = () => setOpen(false)
  const onClear = () => setQuery({ limit: 1000 })

  const onClickSearch = () => {
    setQueryParams(query)
    setOpen(false)
  }

  return (
    <>
      <IconButton size="large" css={iconButtonStyled} onClick={() => setOpen(true)}>
        <TuneIcon fontSize="inherit" />
      </IconButton>
      <Drawer anchor={'bottom'} open={open} onClose={onClose} css={drawerContainerStyled}>
        <Grid container>
          <Grid container justifyContent={'space-between'} alignItems={'center'} p={2}>
            <Grid item>
              <IconButton onClick={onClose}>
                <CloseIcon css={closeButtonStyled} />
              </IconButton>
            </Grid>
            <Grid item css={titleStyled}>
              絞り込み
            </Grid>
            <Grid item css={clearTextStyled} onClick={onClear}>
              クリア
            </Grid>
          </Grid>
          <Divider css={dividerStyled} />
          <Grid container py={1.5} px={2} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item css={filterTitleStyled} pr={4}>
              キーワード
            </Grid>
            <Grid item flexGrow={1}>
              <TextField
                fullWidth
                css={textFilterStyled}
                color={'primary'}
                placeholder="キーワードで検索..."
                variant="outlined"
                value={query?.query_is_keyword || ''}
                onChange={(e) => setQuery({ ...query, query_is_keyword: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Divider css={dividerStyled} />
          <Grid container py={1.5} px={2} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item css={filterTitleStyled}>
              活動エリア
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth css={selectFilterStyled}>
                <InputLabel>{query?.query_is_active_area ? '' : '指定しない'}</InputLabel>
                <Select
                  variant="outlined"
                  onChange={(e) => setQuery({ ...query, query_is_active_area: e.target.value })}
                  value={query?.query_is_active_area || ''}
                  defaultValue={''}
                >
                  {jpKantouAreas.map((area) => (
                    <MenuItem key={area} value={area}>
                      {area}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider css={dividerStyled} />
          <Grid container py={1.5} px={2} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item css={filterTitleStyled}>
              業種
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth css={selectFilterStyled}>
                <InputLabel>{query?.query_is_industry_category ? '' : '指定しない'}</InputLabel>
                <Select
                  variant="outlined"
                  onChange={(e) =>
                    setQuery({
                      ...query,
                      query_is_industry_category: e.target.value as APISchema['IndustryCategoryType'],
                    })
                  }
                  value={query?.query_is_industry_category || ''}
                  defaultValue={''}
                >
                  {INDUSTRY_CATEGORY_MODEL.map((industry) => (
                    <MenuItem key={industry.type} value={industry.type}>
                      {industry.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider css={dividerStyled} />
          <Grid container py={1.5} px={2} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item css={filterTitleStyled}>
              職種
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth css={selectFilterStyled}>
                <InputLabel>{query?.query_is_job_category ? '' : '指定しない'}</InputLabel>
                <Select
                  variant="outlined"
                  onChange={(e) =>
                    setQuery({
                      ...query,
                      query_is_job_category: e.target.value as APISchema['JobCategoryType'],
                    })
                  }
                  value={query?.query_is_job_category || ''}
                  defaultValue={''}
                >
                  {JOB_TYPE_SELECT_MODEL.map((job) => (
                    <MenuItem key={job.type} value={job.type}>
                      {job.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider css={dividerStyled} />
          <Grid container justifyContent="center" pt={5} pb={8} px={3}>
            <Button size={'large'} variant="contained" css={appPrimaryButtonStyled} onClick={onClickSearch}>
              検索する
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </>
  )
}
export default React.memo(UserFilterDialog)

const iconButtonStyled = css`
    position: fixed;
    z-index: 1000;
    bottom: 88px;
    right: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: ${appColor.App.accent};
    color: ${appColor.Text.white};
    &:hover {
      opacity: 0.9;
      background: ${appColor.App.accent};
    }
    &:active {
      background: ${appColor.App.accent};
    }
    &:focus {
      background: ${appColor.App.accent};
    }
    &:disabled {
        background: ${appColor.Service.grayHard};
        color: ${appColor.Text.white};
        opacity: 0.5;
    }
`

const drawerContainerStyled = css`
    & .MuiDrawer-paperAnchorBottom {
        max-height: 80%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
`

const closeButtonStyled = css`
    color: ${appColor.Text.primary};
`

const titleStyled = css`
  font-family: ${notojp.style.fontFamily};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: ${appColor.Text.primary};
`

const clearTextStyled = css`
  font-family: ${notojp.style.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: ${appColor.Service.purple};
`

const dividerStyled = css`
  width: 100%;
  border-color: ${appColor.Text.negative};
  opacity: 0.5;
`
const filterTitleStyled = css`
  font-family: ${notojp.style.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: ${appColor.Text.primary};
`

const textFilterStyled = css`
  font-family: ${notojp.style.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: ${appColor.Text.primary};
  input {
    padding: 12px 0;
  }
  fieldset {
    border-radius: 40px;
  }
`

const selectFilterStyled = css`
  label {
    transform: translate(14px, 13px) scale(1);
  }
  font-family: ${notojp.style.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: ${appColor.Text.primary};
  & .MuiSelect-select {
    padding: 12px 0 12px 16px;
  }
`
