import { APIPaths, APISchema } from '@/apis/api-config'
import { appColor } from '@/styles/app-colors'
import { notojp } from '@/styles/app-typography'
import { Grid, css } from '@mui/material'
import React, { useState } from 'react'

type Props = {
  queryParams: APIPaths['/app/users/list']['get']['parameters']['query']
  setQueryParams: (queryParams: APIPaths['/app/users/list']['get']['parameters']['query']) => void
  pagination: APISchema['WithPageNation']
}

const UserTableHeader: React.FC<Props> = (props) => {
  const { queryParams, setQueryParams, pagination } = props
  const [checked, setChecked] = useState(false)

  // useEffect(() => {
  //   if (queryParams?.query_is_exists_conversation === false) {
  //     setChecked(true)
  //   }
  // }, [queryParams])

  // useEffect(() => {
  //   if (checked) {
  //     setQueryParams({ ...queryParams, query_is_exists_conversation: false })
  //   } else {
  //     setQueryParams({ ...queryParams, query_is_exists_conversation: undefined })
  //   }
  // }, [checked])

  return (
    <Grid container justifyContent={'space-between'} py={0.5} pl={2} pr={1} alignItems={'end'}>
      <Grid item xs={5} css={totalStyled}>
        合計: {pagination.total_count}人
      </Grid>
      {/* <Grid item>
        <FormControlLabel
          control={<Checkbox size="small" checked={checked} onChange={() => setChecked(!checked)} />}
          label="未やりとりユーザのみ"
          css={checkboxStyled}
        />
      </Grid> */}
    </Grid>
  )
}
export default React.memo(UserTableHeader)

const totalStyled = css`
    font-family: ${notojp.style.fontFamily};
    font-size: 12px;
    font-weight: 500;
    color: ${appColor.Text.primary};
`
const checkboxStyled = css`
    & .MuiButtonBase-root {
      padding: 0 4px 0 0 ;
    }
    span {
      font-family: ${notojp.style.fontFamily};
      font-size: 10px;
      color: ${appColor.Text.primary}; 
    }
`
