import { useMediaQuery, useTheme } from '@mui/material'

export type MuiMediaQuery = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const useCustomMediaQuery = () => {
  const theme = useTheme()
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'))
  const isTablet = !useMediaQuery(theme.breakpoints.up('md'))
  const isDesktop = !useMediaQuery(theme.breakpoints.up('lg'))
  const isMonitor = !useMediaQuery(theme.breakpoints.up('xl'))
  return { isMobile, isTablet, isDesktop, isMonitor }
}

export const useBetweenMediaQuery = (min: MuiMediaQuery, max: MuiMediaQuery) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.between(min, max))
}
