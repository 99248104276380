import cliTruncate from 'cli-truncate'
import * as EmailValidator from 'email-validator'

// 全角文字チェック
export function isIncludedDoubleByteChar(str: string) {
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    // Shift_JIS: 0x0 ～ 0x80, 0xa0 , 0xa1 ～ 0xdf , 0xfd ～ 0xff
    // Unicode : 0x0 ～ 0x80, 0xf8f0, 0xff61 ～ 0xff9f, 0xf8f1 ～ 0xf8f3
    if (
      (char >= 0x0 && char < 0x81) ||
      char == 0xf8f0 ||
      (char >= 0xff61 && char < 0xffa0) ||
      (char >= 0xf8f1 && char < 0xf8f4)
    ) {
      // 半角文字
    } else {
      // 全角文字
      return true
    }
  }
  return false
}

export const verifyEmail = (email: string): string => {
  if (!email) return 'メールアドレスが設定されてません'
  if (isIncludedDoubleByteChar(email)) {
    return 'メールアドレスに全角文字を含めないでください'
  }
  if (!EmailValidator.validate(email)) {
    return 'メールアドレスのフォーマットが誤っています'
  }
  return ''
}

// 半角英数字をそれぞれ1種類以上含む6文字以上100文字以下の正規表現
export const verifyPassword = (password: string): string => {
  if (!password) return 'パスワードが設定されていません'
  if (isIncludedDoubleByteChar(password)) {
    return 'パスワードに全角文字を含めないでください'
  }
  if (!password.match(/^(?=.*?[a-z])(?=.*?\d)[a-z\d]{6,100}$/i)) {
    return 'パスワードは、6文字以上でかつ、半角英数字をそれぞれ1種類以上含めてください'
  }
  return ''
}

export const verifyPhoneNumber = (phoneNumber: string): string => {
  // if (!phoneNumber) return "電話番号が設定されていません";
  if (!phoneNumber) return ''
  if (!phoneNumber.match(/^0\d{8,12}$/i)) {
    return '電話番号のフォーマットが誤っています'
  }
  return ''
}
export const verifyAppCode = (appCode: string): string => {
  if (!appCode) return 'コードが設定されてません'
  if (appCode.length < 6) return 'コードは6文字以上に設定してください'
  if (isIncludedDoubleByteChar(appCode)) {
    return 'コードに全角文字を含めないでください'
  }
  if (!appCode.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/g)) {
    return 'コードのフォーマットが誤っています'
  }
  return ''
}
// 全角カナの判定.
export const validZenkakuKana = (name: string) => {
  if (!name) return ''
  if (!name.match(/^[ァ-ヶー]*$/)) {
    return '全角カタカナで入力をお願いします'
  }
  return ''
}
// URL の判定
export const validUrl = (url: string) => {
  if (!url) return ''
  try {
    new URL(url)
    return ''
  } catch {
    return 'URLのフォーマットが誤っています'
  }
}
// 数字以外を入力させないハンドリング
export const handleKeyDownValidNumber = (event: React.KeyboardEvent<any>) => {
  if (['Backspace', 'Tab', 'Enter', 'Meta', 'Alt', 'CapsLock', 'Control'].includes(event?.key || '')) return
  if (event.key.startsWith('Arrow')) return
  if (!event.key.match(/[0-9]/)) {
    event.preventDefault()
  }
}
// CSVエスケープ文字を適応する
export const escapeForCSV = (original: string | null | undefined): string => {
  if (!original) {
    return ''
  }
  return `${original.replace(/"/g, '""').replace(/,/g, ',')}`
}

// URLの正規表現
export const urlRegex = /^(https?:\/\/[^\s]+)/g

// 文字列を指定した長さで切り捨てる
export const textTruncate = (v: string, length = 20): string => `${cliTruncate(v || '-', length)}`
