import { useCustomMediaQuery } from '@/custom-hooks/use-custom-media'
import { appColor } from '@/styles/app-colors'
import { Grid, css, useMediaQuery } from '@mui/material'
import React from 'react'
import Slider, { Settings } from 'react-slick'

const AddContentsSlickCard: React.FC = () => {
  const { isMobile } = useCustomMediaQuery()
  const isXs = useMediaQuery('(max-width:390px)')

  const SliderSettingProps: Settings = {
    // autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    swipeToSlide: true,
    lazyLoad: 'progressive',
    speed: 700,
    centerMode: true,
    className: 'center',
    centerPadding: isXs ? '48px' : isMobile ? '72px' : '100px',
    slidesToShow: isMobile ? 1 : 2,
    nextArrow: <></>,
    prevArrow: <></>,
  }

  return (
    <Slider {...SliderSettingProps} css={sliderStyled}>
      <Grid>
        <img src="/test/Group17.png" alt="" css={imgStyled} />
      </Grid>
      <Grid>
        <img src="/test/Group18.png" alt="" css={imgStyled} />
      </Grid>
      <Grid>
        <img src="/test/Group19.png" alt="" css={imgStyled} />
      </Grid>
    </Slider>
  )
}
export default React.memo(AddContentsSlickCard)

const sliderStyled = css`
  & .slick-dots {
    & .slick-active {
      button::before {
        color: ${appColor.Service.orange};
      }
    }
  }
  & .MuiGrid-root {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
`

const imgStyled = css`
  max-height: 144px;
`
