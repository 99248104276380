import { APISchema } from '@/apis/api-config'
import { authUserAtom } from '@/global-state/jotai-atoms'
import { appColor } from '@/styles/app-colors'
import { notojp } from '@/styles/app-typography'
import { Button, css } from '@mui/material'
import { useAtom } from 'jotai'
import React, { useState } from 'react'
import RequestSendDialog from '../dialog/RequestSendDialog'

type Props = {
  user: APISchema['User']
}

const ProfileCardButton: React.FC<Props> = (props) => {
  const { user } = props
  const [authUser] = useAtom(authUserAtom)
  const [openRequestSendDialog, setOpenRequestSendDialog] = useState(false)

  const isMe = user?.id === authUser?.id
  const isRequestCatch = user?.UserConversationRels?.some((v) => v.conversation?.user?.id === authUser?.id)
  const isRequestSend = user?.Conversation?.some((v) => v.UserConversationRels?.some((v) => v.userId === authUser?.id))

  const disabled = isRequestCatch || isRequestSend

  let buttonLabel = 'リクエストを送る'
  if (isRequestCatch) {
    buttonLabel = 'リクエスト受信中'
  }
  if (isRequestSend) {
    buttonLabel = 'リクエスト送信中'
  }
  if (isMe) {
    buttonLabel = '自分のプロフィール'
  }

  const onClickButton = () => {
    if (!disabled) {
      return setOpenRequestSendDialog(true)
    }
  }

  return (
    <>
      <RequestSendDialog open={openRequestSendDialog} onClose={() => setOpenRequestSendDialog(false)} user={user} />
      <Button
        fullWidth
        variant="contained"
        size="small"
        css={buttonStyled(isRequestCatch, isRequestSend)}
        onClick={onClickButton}
        disabled={isMe}
        disableRipple={disabled}
        disableFocusRipple={disabled}
        disableTouchRipple={disabled}
        disableElevation={disabled}
      >
        {buttonLabel}
      </Button>
    </>
  )
}

export default React.memo(ProfileCardButton)

const buttonStyled = (isRequestCatch?: boolean, isRequestSend?: boolean) => css`
    font-family: ${notojp.style.fontFamily};
    font-size: 12px;
    opacity: 0.8;
    background: ${
      isRequestCatch ? appColor.Service.orange : isRequestSend ? appColor.Service.green : appColor.Service.purple
    };
    color: ${appColor.Text.white};
    &:hover {
        background: ${
          isRequestCatch ? appColor.Service.orange : isRequestSend ? appColor.Service.green : appColor.Service.purple
        };
        opacity: ${isRequestCatch || isRequestSend ? 0.8 : 1};
    }
`
