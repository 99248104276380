import { useAppApi } from '@/custom-hooks/use-app-api'
import { authUserAtom, userListQueryParamsAtom } from '@/global-state/jotai-atoms'
import { CircularProgress, Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import React from 'react'
import AddContentsSlickCard from './atom/AddContentsSlickCard'
import ProfileCard from './atom/ProfileCard'
import UserFilterTab from './atom/UserFilterTab'
import UserTableHeader from './atom/UserTableHeader'
import WelcomeSalonsAlert from './atom/WelcomeSalonsAlert'
import UserFilterDialog from './dialog/UserFilterDialog'

const HomePage: React.FC = () => {
  const { userApi } = useAppApi()
  const [authUser] = useAtom(authUserAtom)
  const [queryParams, setQueryParams] = useAtom(userListQueryParamsAtom)

  const result = useQuery({
    queryKey: ['/app/users/list', queryParams, authUser?.id],
    queryFn: () => userApi.appGetUserList(queryParams),
    enabled: !!authUser?.id,
  })

  return (
    <Grid container pb={5}>
      <UserFilterDialog queryParams={queryParams} setQueryParams={setQueryParams} />
      <UserFilterTab queryParams={queryParams} setQueryParams={setQueryParams} />
      {!result.isSuccess ? (
        <Grid item xs={12} p={2}>
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} py={2}>
            <AddContentsSlickCard />
          </Grid>
          <WelcomeSalonsAlert totalCount={result.data.page.total_count} />
          {result.data.page.total_count ? (
            <UserTableHeader queryParams={queryParams} setQueryParams={setQueryParams} pagination={result.data.page} />
          ) : null}
          {result.data.data?.map((user) => (
            <Grid item xs={12} py={1} key={user.id} px={1.5}>
              <ProfileCard user={user} />
            </Grid>
          ))}
        </>
      )}
    </Grid>
  )
}
export default React.memo(HomePage)
